export const messed_wallets = [
  "0x266468e75F13a4383c9917Aa564A30FF9eD9A7B6",
  "0xe1308a8d0291849bfFb200Be582cB6347FBE90D9",
  "0xa907f60543F482583dd6F60D6623892969dD7E49",
  "0xCaD3887923B39cD2b0B6d13538C4ecB7C5EE9825",
  "0x518300cbA295D4785c6EcF012B4d17357B0eb631",
  "0x7430ee882D25a29a2462F504Fecf4385e2b014f7",
  "0x0347D2B5A580D2B8751Ef1dA87dCe03556f08316",
  "0xc1a69F906Ce4366C0C77e0219b3132FeA0969231",
  "0xc1DfE6D5A6B1Ca01dd568A4C435B3958079500A1",
  "0xB30dD1198Feed1e22EC969f61EEd04cB75937adf",
  "0x6DD6934452eB4E6D87B9c874AE0EF83ec3bd5803",
  "0x8dB916FadB1C973D610346F0a1a8e4C81acF0390",
  "0x7e5ce10826ee167de897d262fcc9976f609ecd2b",
  "0x71E6724aeb8BD64C4d17EDf280FDAF4324864028",
  "0x1d921dff757610FbDB0073479e12c0a07d382677",
  "0xAA541230833aB2Ca3F66C7E209512816C0C67EB4",
  "0xd0F9D16C5fA6DF47C16854d30B81201C03c2253f",
  "0xc84E4D0BD6B0d2bBc487223cACB412aEe8451a09",
  "0x8b8Ab70bD3f4969320079c344698cB40B30967C3",
  "0xb3c13a25094c5c74fcf2da88f7c5ae3888a2b05d",
  "0x1957B5af2DC75F4927784A8fFa6787389e7f244b",
  "0xeA1D10D8737989337326C48930f46cb75EF6d889",
  "0x3491694c39202b54C21EDD136112DA37d62a64D3",
  "0x62cEa17DAA41f9d56aA735cd1C0C7254666b5D27",
  "0x5cf17d7ff8073c918669d4ec46800f9973802d59",
  "0xec7021433c5D93c2BAEb436d0Bd78286470D320b",
  "0xf5ec4e9a3EAa331631184EB54Eb8Ea5DfbFE6166",
  "0x0BB602F88BF886282fF69d4Cec937cc2A7d9E19A",
  "0x0AFBEA5597875c33047aB0004575f636e652C49e",
  "0x8305550b9d85dEF1C09B833EA0b492914A612b48",
  "0x3e95B8E249c4536FE1db2E4ce5476010767C0A05",
  "0x586af561d822eE8845A160e871Ff7160F2A7E6aD",
  "0x314Ef410a94082990692f083cCa46Fc5A9bD0dCa",
  "0x58A35cF59d5C630c057aF008a78bc67CDc2EC094",
  "0x715aCC4a912f979279b9B3d3a46A3cF1e006C033",
  "0x8f51DC0791CdDDDCE08052FfF939eb7cf0c17856",
  "0xcEc37262d84EE63adea1639569124B55ce41802b",
  "0xC1aA6c04BAD0A0Dad9dEeA33a3e40999627942CE",
  "0x319B7C667dD6476e6EAe184430b7bD16a050A374",
  "0xF4A1B6c60C50FDD55c369AD867001450b1f45344",
  "0xc2fB4B3EA53E10c88D193E709A81C4dc7aEC902e",
  "0x4CE948770f64FE318Df28e756B2e10ee3fc9164C",
  "0x39E856863e5F6f0654a0b87B12bc921DA23D06BB",
  "0x7d990c17573b9Fe410767723080fcb1734Ff7D5B",
  "0xd1800C1Df4e798954b992a1b8C8fB7C02c3957D6",
  "0xeE32255585c54733F80DdFED53384AF40304e476",
  "0x39682ACD082B7e617F26b6c2216C19068262aFd6",
  "0xa05281a123cC4D20621cEa90937dC359bb41F72D",
  "0x39EAFf05Fd5402B1A9d4572AbC25994bd314afca",
  "0xC06C7C6ec618DE992d597D8e347669EA44eDe2bc",
  "0x1Ab75FEA1f27A6dfAfF81b0371AB6d57E112906B",
  "0xBdA872F069f434138225bFE2918b1071cc3E6425",
  "0x7e22d9514a99793E80D4FB5E3A9Dfd4931F47c4c",
  "0x732Fb405fa75Fa66192d42B40852E8542aB1CEf5",
  "0xE04885c3f1419C6E8495C33bDCf5F8387cd88846",
  "0x64DEfa36Cd321A44ADDDad64414A9060D1D98599",
  "0xA7323954BC398481a26E8fCD5ad4ddF9b7a222ad",
  "0x52fD7b63B16AC94cEd97838C25E2660dD6b0E0D1",
  "0xCccC763a46fc13C21adae08aF62d7132dAC350b9",
  "0xF1Ebd55eCdf7fEd994885aE66A92c27f8D363bDf",
  "0xBB1EDB8c64D7BaA322FADfD13d4A5Db96d02A2E1",
  "0x1e55C85801a2C4F0beC57c84742a8eF3d72dE57B",
  "0x592bd7Ac7CEDF55De38Dc419cCbc16D91Dca2A48",
  "0x3B95717Ec102A8550856E254E85aC0890De344c2",
  "0xfD3c35aF3b4bfa18cF58b419dC1e456aA5e62197",
  "0x3E731ECd8973b6b6c2Aa4d3FC102B03c8364E623",
  "0xbEc1aD8859a454487ee909fD08695d8F3276477d",
  "0xB2FBa7f4384C517E1524846aba89393b3eF42042",
  "0x8681aF1c708c0CF0d8C466c68032b72F4E167690",
  "0x84e1056eD1B76fB03b43e924EF98833dBA394b2B",
  "0x3Ec4513e78aBaAc88eeeD41a36B4E24cFA336B46",
  "0x3E731ECd8973b6b6c2Aa4d3FC102B03c8364E623",
  "0x7851b240aCE79FA6961AE36c865802D1416611e7",
  "0x3Cc1A943B27c2AF7c6dcF3Be0137f66c2f22E712",
  "0x7020D15dD9AC18dc0701f14aE32AD100efbB8Fd6",
  "0xb5138ED0C2419233b7004713F1D52798154cB935",
  "0xbaa50B649494f1b947b75ca80D9cc108313EbE33",
  "0xd0F9D16C5fA6DF47C16854d30B81201C03c2253f",
  "0x09e1c9146394DFF44eaab37381d34251ed86fCdd",
  "0xE75B1964fd7E677b0ca6bB0eB08Aca7e3Da957F0",
  "0xbf9d77985a28c938bf2ff3b9b40abc21119bb895",
  "0xf5ec4e9a3EAa331631184EB54Eb8Ea5DfbFE6166",
  "0x37d7C7c7AC09532aB607bFC4bAAd86beb35e493d",
  "0x6a6bA11926e99761Dd9A3DD4af236ec7BaE33325",
  "0x9387C9C9Fc8F91CD8f8bE9626006D0D7FAc81B0d",
  "0x066Caba6FfEebc9d4DC6d29d2376065aC3f5e115",
  "0xd14e3ACf8d60F22e582B8453fc1554a35BDAb151",
  "0xC3268DDB8E38302763fFdC9191FCEbD4C948fe1b",
  "0xf4B03870807059042B98C7abe889d5E9ed787371",
  "0x0f1F3DAf416B74DB3DE55Eb4D7513a80F4841073",
  "0x773Ff96Ffd3910C4bF64F503652FBD8151970E0A",
  "0xd906298742A9e52142AfEC8f0a479BfCfb0B965C",
  "0x1957B5af2DC75F4927784A8fFa6787389e7f244b",
  "0x45d1Fb2bBC085A24e81Ed9e8E02cEf35CE21eC46",
  "0x54Ec1b5598267148E447E0319D6aA8FAd9084d3E",
  "0xf7253A0E87E39d2cD6365919D4a3D56D431D0041",
  "0xF8D1d34956cEa24718cf8687588D6FeDbc6d9AA6",
  "0x9b7b2B4f7a391b6F14A81221AE0920A9735B67Fb",
  "0xd26A2bd8A59eA00bD836142cc6BE9717361a3b51",
  "0x839395e20bbB182fa440d08F850E6c7A8f6F0780",
  "0xAfdF34bf8CEA94062B8002F6d46282186dd6f0e9",
  "0x321F9b564b1bEE226AaBbb6FA1E850f26ce10603",
  "0x7EC86cfbE184642a140E543c4a54Cb624BDEc6CD",
  "0x0035cC37599241D007D0AbA1Fb931C5FA757f7A1",
  "0x319B7C667dD6476e6EAe184430b7bD16a050A374",
  "0xcb09c9e098d35aae65644f80dd8827ff8d740cd7",
  "0xf0ee04af67809247ef194443e388e42933279ef3",
  "0xD00E5109A8b25CEC2B6F5982646De18AbEaf73B1",
  "0xE72E4014276364A12144b18C57a786434FE02Bb9",
  "0x44B408f8df912883eb988F33974db0d238E86527",
  "0xe6462D0A9311b60effa483C2A666e2911E68dEA4",
  "0xda70761A63d5D0DdE3bdE3b179126127Cccb44b3",
  "0x3987426f3C429214BC078750948461A2abe03ec7",
  "0x540e198ba66d6EcF300261C240dcD9887b713E75",
  "0x64DEfa36Cd321A44ADDDad64414A9060D1D98599",
  "0x4191C4e2c449a31460fA2f272Db0319693b45C28",
  "0xd26a3F686D43f2A62BA9eaE2ff77e9f516d945B9",
  "0x4CE948770f64FE318Df28e756B2e10ee3fc9164C",
  "0xe66396187e3021AF87FbbE66ee628D13b7D1Dd62",
  "0xF088C9AcE59CdD5dEbB6aD2c23a0dda523bfF206",
  "0xB950e0E108546743Af96eB493D4FF2AbC63816dB",
  "0x2B62287735C0175DdcdD5606d66A17D341385bEb",
  "0x735B56CDb9af99087dE470aC7EecFBCc993e2512",
  "0x6d9ABa400a2a487a5Fb76c6d56518835553CD284",
  "0x212BB811bD9F71fdaD67caAba668f02794c34e3D",
  "0x5c3D9731F1B94D61aC1a64Eb71294eD771c826D0",
  "0x967142917BC5886cAD4562a2AFBafbacc256a88F",
  "0xcae07057a704566B81B5CdDD28C8746094d2b1C3",
  "0x8f51DC0791CdDDDCE08052FfF939eb7cf0c17856",
  "0x109B2D89b2b503a553bbc9c8755ABF736dE5F0DE",
  "0xB7D854071E2eEE78117Ea80Ea9361F645D6d7717",
  "0x2fAb8A8Eb6fF05A9DdB14042b55A7DC92ca84701",
  "0x1Ab75FEA1f27A6dfAfF81b0371AB6d57E112906B",
  "0xf2a07BC367b3ae865D07456eDD95b7819773B2d0",
  "0x9271508Ed0Ef8F9D75559c3600b77d86443aF351",
  "0xC1aA6c04BAD0A0Dad9dEeA33a3e40999627942CE",
  "0xba9d7cbbD34777A6eB787894778E75603Cf56eB3",
  "0x314Ef410a94082990692f083cCa46Fc5A9bD0dCa",
  "0x1e55C85801a2C4F0beC57c84742a8eF3d72dE57B",
  "0xB2FBa7f4384C517E1524846aba89393b3eF42042",
  "0x9fb101BCd5C7BaEA48E29c45a3F0BD6caAf5709E",
  "0x7E052Ef7B4bB7E5A45F331128AFadB1E589deaF1",
  "0xCF61eBD3613684a53E48eE85d8fBc80C5156c479",
  "0x588f79EC1f0f1e8FB5fdeD80BFdb6a80e12EBc47",
  "0xd15AA78D8f0F77D6Dd00589e5B8901187F892dF7",
  "0x07D5c2f0eC7f791F1BB0760C7BaC21Eef10a0956",
  "0x0DC825282d60cEB87c87A58AB1B1d47384ae9Aa5",
  "0x0553b35743639be26870CEe5a1Fd2BEC457878d6",
  "0xbf46D2161045251cB97D0B41929bc1D36044E1a0",
  "0xC46c67Bb7E84490D7EbdD0b8ecDaca68Cf3823F4",
  "0x824bF0E51E0757aF24Dc30E4B83FC51c4B84fD85",
  "0xcBe160f43f45145ac82E72aC622c9739DD90052C",
  "0xCF94708A01A7D91353215751eABd1A68Ff7b11cd",
  "0x67a96643187983F93325aFeCc754A3fFb569998B",
  "0xe935CDaaCeCA55a32C34411d014F632D2F40916A",
  "0x967142917BC5886cAD4562a2AFBafbacc256a88F",
  "0xE340b00B6B622C136fFA5CFf130eC8edCdDCb39D",
  "0xB00d318285c95CF5F2b7e7bfBF9b013984B36334",
  "0x4E39661537007406a66C7Cc3A974Cf30b2EB35B2",
  "0x66e4476BAc0CB348f893924d457cFA3eE64335eb",
  "0xba9d7cbbD34777A6eB787894778E75603Cf56eB3",
  "0xC8B810Fe39952AA65CA5A9387a3546B9b6bF5780",
  "0x05Be06b424448052193d53Db8955D246a8dbb98A",
  "0x3211695ED0b0c277E7340dA9A6ED025463774797",
  "0xF3Fb852A7a9486201B28311ba316721F04D2690E",
  "0x2B50d53c2d10954aEA741ae61001c3fb77717e5e",
  "0xd11256d99f8833beE0B99203DDcfe4cd6c823d8D",
  "0x9A4E248244b4ce440d7085d563631B5545972F33",
  "0xb01474b50382fAe1A847E3A916ECDf07Ba57BcC7",
  "0x12efDCBbCDd6695109adA38e33c56173C2FfE2C3",
  "0xb8150a1B6945e75D05769D685b127b41E6335Bbc",
  "0x2d0Df096b42c9ff55E71E57250A47D2D0551d7Fb",
  "0x9150C94dE175C6FA4d766a4e951E9c7ed204Ad1a",
  "0xd291aCbf8C3429061C70A03Fb555317D9FF5def7",
  "0xa8fE8292d1b56650ab43Fe39279cf531147C6094",
  "0x8fC5B7F15909646Ad94D7DAf6823FD9732ccb403",
  "0xe83E0b36bc68c1407B81B6d42CA4bd23FC309517",
  "0x1DBA1131000664b884A1Ba238464159892252D3a",
  "0x58A35cF59d5C630c057aF008a78bc67CDc2EC094",
];

export const july5_3 = [
  "0x54ae554a9C462dcA33b89955ff8e96c9d463d1d2",
  "0x254a4E3E7dB1eAcF3E91D810FcF58e48fAf45E3B",
  "0x59d35a531aa78fcad3f1d06fabe4c23a574b8d07",
  "0x1B1e924A0A9c86a9023BE9791bb81ECFb8CC4011",
  "0xf949326673f8B87Fa36b6C41B8Cf162E0F14F5ef",
  "0x65d24ff4db02E641A6589984eFdDff1eA4fbCB60",
  "0x2A192DE756Fd40d08e9a5F9082AE1c07fcfF5Ae4",
  "0x60583563D5879C2E59973E5718c7DE2147971807",
  "0x9CAD8C527207208FDEb5EA176c8fBC08EafFF1a6",
  "0x7C6bA33A84624F3f2665B65dEe74476B6F4d3B78",
  "0x223138E77a12Eaf5b767955a90565fDAf971910a",
  "0xf44B5cA7C8590D0c146f1073f2D20f21949Ee548",
  "0xC8E125fF49012986656eeF743b1C535Ebd026153",
  "0x61702EAAc1795DbA607D23E29a4B27076E9eA047",
  "0x4EEc33Db764b7Fe34F038E0D66F8f0F73F5f6CdB",
  "0xc64E64BFc893d8C5787DDEFD818e2A843690EF3E",
  "0x859481eE6854f4078dd19D50Dcf10919DFaE6786",
  "0x79fbb73DB70Ed9F21F8e6681a1e5AA90aC99Fe40",
  "0xDAFAc2F9ff00dBC3Ad887217eaE4cd0B809537C8",
  "0xeb5e5fdc76c0761b0a69c1ed9fa05596582ef0c6",
  "0x15c41C6b9A1C31B08DD1Ac31382F17E9c60bE508",
  "0xe872aB4c7e41eE9CDa5E45D73Fc53a6108f0aedE",
  "0xE514b96bcC117BD3cE1B2d422B17eEE051FF79b9",
  "0x30FB8d5A5F4C4177dE673619b1f19580c3e780E0",
  "0xe1505BE857071AcE834C6a9798DB38bA7BA0fFc7",
  "0x182CdDb0f0818255cdad600720B3B2Ba5c73888E",
  "0xb7f781f7da4e9f128fef5559edf4c899eb954ac9",
  "0xEBa3Cf8a7a1Dc26e89F99b74Bb06D9b8bA041914",
  "0x5C52e0e20E3CE8d9B0CdDAC20B7a62Ae45d9e3a6",
  "0x7C6bA33A84624F3f2665B65dEe74476B6F4d3B78",
  "0x84C5C3Ff0cAa0eB59c53320DAf4cE69405FA583a",
  "0x1c958a33baAe5aCebA43e28F1370b754B43fd887",
  "0xF604AE31406686fc5df3F0f8AbF8277A76960e92",
  "0x1355bec248E42f227C62101c6268f3484e08d92e",
  "0xa0571dC54C0f75C27ba1Bb27dd2A90DbFd42F360",
  "0xF634BffFE56fc18062695cdA2eB16D1507Be53D1",
  "0xE3a6babB1FE3D34128b9a66EA5314842D6B6f3fc",
  "0x105bCeE1d554a78a229Cf15202E29b411b04C846",
  "0xABA30bac92c3d2a5F36f2A0D476c5FFA6a80fDaa",
  "0x91a03df73b096F6921fC256476d3A3db50C994b8",
  "0xFe2711d81c82E9e832fDB3A50ba102acA5466061",
  "0x61cF830C9b7C3AC0C60137AB2D7ebA16da2dDF73",
  "0x5AeE2397b39B479B72bcfbDA114512BD3329E5aC",
  "0x474c08382bBFBc0924b351521d59A8EFe074e761",
  "0xDe3715717BA52ea68Bf212FD8F3064563781d495",
  "0xc41B8d4d0B6490fE131e26bfDb77eA49d4354aaE",
  "0xb8Ac9C178f07247acdAe65e26eF757E01B6Ef501",
  "0xcb77E0C9ca70C750fbe49C44F094E1F2Dd4f599c",
  "0x20DBcF81644aAbCd4Fce1D287df217ba72762964",
  "0xC1150c071207276Dc35afDC116925f9C5e007292",
  "0x3a6CdC355295E3F34bcEb90DCD99A2B657cbD719",
  "0xb1b779f7bd63CE264da845511E27eC1Cb4De42Bf",
  "0x77bEc88915f31c29F151Cd0d4F00CAD44E791A40",
  "0x9D0C9a4efb7D88e020eB7EEBe406c138be157501",
  "0x3Fe19853AE001510a0cff3c2eB1EDDc2da59A4a5",
  "0x45e3bA306E0A490ABa500ADD26628f15f2DcBC43",
  "0x7a7D5DE0c6E434C6a47551FdAA61eCC4c1d80b1b",
  "0xE77a81E533c22057dc2f29697e671bf0Ea8D8496",
];
